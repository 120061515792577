import Vue from 'vue'
import App from '@/App'
import {DEBUG_MODE, PRODUCTION_MODE} from '@/config/env'

// I18n

import VueI18n from 'vue-i18n'
import * as messages from '../lang'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages
})

Vue.config.debug = DEBUG_MODE
Vue.config.productionTip = PRODUCTION_MODE

const ChatBotWidget = {
    init (options = {}) {
        // Load fontawesome

        const head = document.head || document.getElementsByTagName('head')[0]
        const link = document.createElement('link')

        link.rel = 'stylesheet'
        link.type = 'text/css'
        link.href = 'https://cdn.moonrhythm.io/fontawesome-pro/6.5.1/css/all.min.css'

        head.appendChild(link)

        // Initialize widget

        const appendScript = () => {
            const widgetContainer = document.createElement('div');

            document.body.appendChild(widgetContainer)

            new Vue({
                i18n,
                render: h => h(App, { props: options })
            }).$mount(widgetContainer)
        }

        document.readyState === 'loading'
            ? document.addEventListener('DOMContentLoaded', appendScript)
            : appendScript()
    }
};

export default ChatBotWidget


